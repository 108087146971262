import Modal, { IModalRef } from '@components/Modal';
import { IOrderPaymentsCharges } from '@hooks/useCurrentOrder/models';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { format, getDate, parseJSON } from 'date-fns';
import { UserData } from '@store/modules/auth/sagas';
import { CancelButton } from '@components/OrderDetailPayment/styles';

import ptBR from 'date-fns/esm/locale/pt-BR/index.js';
import CancelChargeModal from './CancelChargeModal';
import TransactionDetails from './TransactionDetails';
import PaymentMethod from './PaymentMethod';
import ChargeStatus from './ChargeStatus';

const ChargeDetails: React.FC<{
  charge: IOrderPaymentsCharges;
  chargeIndex: number;
  paymentType: string;
  orderStatus?: string;
}> = ({ charge, orderStatus }) => {
  const language = useSelector(state => state.ui.language);
  const [dateLanguagePack, setDateLanguagePack] = useState<Locale>(ptBR);
  const cancelChargeModalRef = useRef<IModalRef>(null);

  const modals = useMemo(
    () => (
      <Modal ref={cancelChargeModalRef} size="small">
        <CancelChargeModal
          charge={charge}
          hide={() => {
            cancelChargeModalRef.current?.show();
            cancelChargeModalRef.current?.hide();
          }}
        />
      </Modal>
    ),
    [charge],
  );

  useEffect(() => {
    const languageSet = language;
    import(`date-fns/locale/${languageSet}`)
      .then(res => setDateLanguagePack(res.default as Locale))
      .catch(() => {
        setDateLanguagePack(ptBR);
      });
  }, [language]);

  const mountDateAndTime = (date: Date | string | undefined) => {
    if (!date) {
      return (
        <>
          <p> - </p>
        </>
      );
    }
    return (
      <p>
        {getDate(parseJSON(date))}{' '}
        {format(parseJSON(date), 'MMM', {
          locale: dateLanguagePack,
        })}{' '}
        {format(parseJSON(date), 'yyyy', {
          locale: dateLanguagePack,
        })}{' '}
        às{' '}
        {format(parseJSON(date), 'p', {
          locale: dateLanguagePack,
        })}
        {' min'}
      </p>
    );
  };

  const renderCancelButton = () => {
    const userChargeRefund = UserData.getInstance()
      .getUserScopes()
      .find(scope => scope.scope === 'CHARGE_REFUND');

    if (charge.amount > 0 && userChargeRefund) {
      return (
        <CancelButton
          onClick={() => {
            cancelChargeModalRef.current?.show();
          }}
        >
          CANCELAR COBRANÇA
        </CancelButton>
      );
    }
    return null;
  };

  return (
    <div className="sectionPanel">
      {modals}
      <div className="sectionContent">
        <div className="subContentVertical">
          <TransactionDetails charge={charge} />
          <div style={{ marginBottom: '2rem' }} />
          <PaymentMethod charge={charge} />
        </div>
        <div className="border" />
        <div className="subContentVerticalRight">
          <>
            <div className="sectionSubContentStatus">
              <div className="subChargeTitle">
                <ChargeStatus charge={charge} orderStatus={orderStatus} />
              </div>
              <div className="subContentStatusType">Capturado</div>
              <div className="subContentValue">
                <div className="detailDate">
                  {mountDateAndTime(charge.paid_at)}
                </div>
                <div className="detailValueAmount">
                  R${' '}
                  {(
                    Number(charge.payload.paid_amount ?? charge.amount) / 100
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className="sectionSubContentStatus">
              <div className="subContentStatusType">Cancelado</div>
              <div className="subContentValue">
                <div className="detailDate">
                  {mountDateAndTime(charge.canceled_at ?? 0 / 100)}
                </div>
                <div className="detailValueCanceled">
                  R${' '}
                  {(Number(charge.payload.refunded_amount ?? 0) / 100).toFixed(
                    2,
                  )}
                </div>
              </div>
            </div>
            <div className="sectionSubContentStatus">
              <div className="subContentStatusType">Atual</div>
              <div className="subContentValue">
                <div className="detailDate">
                  {mountDateAndTime(charge.updated_at)}
                </div>
                <div className="detailValueFinal">
                  R${' '}
                  {(
                    Number(
                      charge.payload.paid_amount -
                        charge.payload.refunded_amount
                        ? charge.payload.paid_amount -
                            charge.payload.refunded_amount
                        : charge.amount,
                    ) / 100
                  ).toFixed(2)}
                </div>
              </div>
            </div>
            <div className="cancelButton">{renderCancelButton()}</div>
          </>
        </div>
      </div>
    </div>
  );
};

export default ChargeDetails;
