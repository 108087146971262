import React from 'react';
import { MdContentCopy, MdOpenInNew } from 'react-icons/md';
import { toast } from 'react-toastify';
import { IOrderPaymentsCharges } from '@hooks/useCurrentOrder/models';
import { IconButton } from '@material-ui/core';
import { LetterLink, Link } from './styles';

const TransactionDetails: React.FC<{
  charge: IOrderPaymentsCharges;
}> = ({ charge }) => {
  return (
    <>
      <div className="sectionSubContentBottomTitle">
        <p>Dados da Transação:</p>
      </div>
      <div className="sectionSubContentBottom">
        <div
          className="sectionSubContentBottomInfo"
          style={{ marginBottom: '2rem' }}
        >
          <div
            className="sectionSubContentBottomInfo"
            style={{ marginBottom: '0.7rem' }}
          >
            Número da Autorização: {charge.payload.authorization_code ?? ' - '}
          </div>
          <div style={{ marginBottom: '0.5rem' }}>
            NSU: {charge.payload.acquirer_nsu}
            <IconButton size="small">
              <MdContentCopy
                onClick={() => {
                  navigator.clipboard.writeText(charge.payload.acquirer_nsu);
                  toast.success('Número copiado para área de transferência.');
                }}
                style={{
                  color: 'rgba(220, 198, 183, 1)',
                  marginLeft: '0.5rem',
                }}
              />
            </IconButton>
          </div>
          {charge.payload.details_url && (
            <LetterLink>
              <Link href={charge.payload.details_url} target="_blank">
                Detalhes de Pagamento
              </Link>
              <MdOpenInNew
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                  verticalAlign: 'middle',
                }}
              />
            </LetterLink>
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionDetails;
